import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";
import { buildingTypeMap } from "../../../../common/building-type-map";
import { projectTypeMap } from "../../../../common/project-type-map";
import { Spinner } from "../../../../common/spinner/base-spinner";
import { Card } from "../../../../components/basics/card";
import { ProjectAttributes } from "../../../../model/project/project-model";
import { classNames } from "../../../../shared/utils/class-names";
import {
  ExcludeParam,
  REFERENCE_LIST_EXCLUDE,
} from "../filter/reference-filter-query-params";
import { formatMarketValue } from "../market-value";
import { compareArray } from "../../../../common/compare-helpers";
import { Tooltip } from "../../../../components/basics/tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { TooltipManager } from "../../../../components/basics/tooltip-manager";

export interface IReferencesTableLayoutProps {
  data?: ProjectAttributes[];
  isLoading?: boolean;
  exportActive: boolean;
  excludeLinesVisible?: boolean
}

const tableCellClassNames = "truncate px-4 print:px-1";

export const ReferencesTableLayout = ({
  data,
  isLoading,
  exportActive,
  excludeLinesVisible = true
}: IReferencesTableLayoutProps) => {
  const [excludeList, setExcludeList] = useState<string[]>([]);
  const [excludeQueryParam, setExcludeQueryParam] = useQueryParam(
    REFERENCE_LIST_EXCLUDE,
    ExcludeParam
  );

  useEffect(() => {
    if (!compareArray(excludeQueryParam, excludeList)) {
      if (excludeQueryParam) {
        setExcludeList(excludeQueryParam as string[]);
      }
    }
  }, [excludeQueryParam]);

  return (
    <Card className="bg-gray-100 print:bg-white border border-gray-50 print:border-0 print:text-md">
      <div
        className={classNames(
          "grid font-special font-semibold items-center justify-left text-gray-600 mb-3",
          exportActive
            ? "grid-cols-[10%_13%_13%_18%_12%_6%_12%_16%]"
            : "grid-cols-[12%_15%_20%_12%_7%_10%_20%]"
        )}
      >
        {exportActive ? (
          <div className={classNames(...tableCellClassNames, "flex flex-row")}>
            Abwählen{" "}
            <span className="print:hidden">
              <TooltipManager>
              <Tooltip
                id="references-exclude"
                tooltipContent={
                  <span className="font-normal">
                    Die ausgewählten Objekte werden <strong>nicht</strong>{" "}
                    exportiert
                  </span>
                }
                align={"left"}
              >
                {" "}
                <InformationCircleIcon className="w-5 mx-2" />
              </Tooltip>
              </TooltipManager>
            </span>{" "}
          </div>
        ) : null}
        <div className={tableCellClassNames}>Land</div>
        <div className={tableCellClassNames}>Stadt</div>
        <div className={tableCellClassNames}>Objektart</div>
        <div className={tableCellClassNames}>Verkehrswert</div>
        <div className={tableCellClassNames}>Jahr</div>
        <div className={tableCellClassNames}>Auftraggeber</div>
        <div className={tableCellClassNames}>Auftragsart</div>
      </div>
      <div>
        {isLoading ? (
          <div className="flex flex-row w-full h-full justify-center">
            <Spinner className="w-8 h-8 stroke-gray-400" />
          </div>
        ) : (
          data?.map((project) => {
            const isExcluded = excludeList.includes(project.displayId);
            return (
              <div
                key={project?.displayId}
                className={classNames(
                  "grid rounded-2xl bg-white mb-1.5 print:mb-1 text-gray-600 py-4 print:py-1",
                  exportActive
                    ? "grid-cols-[10%_13%_13%_18%_12%_6%_12%_18%]"
                    : "grid-cols-[12%_15%_20%_12%_7%_10%_20%]",
                  isExcluded && excludeLinesVisible ? "striped" :
                    isExcluded ? "hidden" : ""
                )}
              >
                {exportActive ? (
                  <div className="flex flex-col items-center justify-center">
                    <input
                      id="ja"
                      name=""
                      type="checkbox"
                      className="h-4 w-4 text-green-500 border-gray-300 rounded mr-2"
                      checked={isExcluded}
                      onChange={() => {
                        if (!isExcluded) {
                          setExcludeList([...excludeList, project?.displayId]);
                          setExcludeQueryParam([
                            ...excludeList,
                            project?.displayId,
                          ]);
                        } else {
                          setExcludeList([
                            ...excludeList.filter(
                              (p) => p !== project?.displayId
                            ),
                          ]);
                          setExcludeQueryParam([
                            ...excludeList.filter(
                              (p) => p !== project?.displayId
                            ),
                          ]);
                        }
                      }}
                    />
                  </div>
                ) : null}
                <div className={tableCellClassNames}>
                  {project?.building?.address?.country}
                </div>
                <div className={tableCellClassNames}>
                  {project?.building?.address?.city}
                </div>
                <div className={tableCellClassNames}>
                  {project?.building?.type
                    ? buildingTypeMap[project?.building?.type].displayString
                    : "Nicht definiert"}
                </div>
                <div className={tableCellClassNames}>
                  {formatMarketValue(project?.building?.marketValue)}
                </div>
                <div className={tableCellClassNames}>
                  {project?.deadlineDate
                    ? dayjs(project?.deadlineDate).year()
                    : "?"}
                </div>
                <div className={tableCellClassNames}>
                  {project?.clientType?.label}
                </div>
                <div className={tableCellClassNames}>
                  {project?.type
                    ? projectTypeMap[project?.type]?.displayString
                    : "Nicht definiert"}{" "}
                </div>
              </div>
            );
          })
        )}
      </div>
    </Card>
  );
};
