import React, {useEffect} from "react";
import Alert from "../../../components/layout/alert";
import {buildRestFilter} from "../../../model/meta/request-utils";
import {useLazyFetchAllQuery as useProjectMapLazyFetchAll} from "../../../model/project-map/project-map-api";
import {IProjectFilter} from "../../../components/filters/project-filter";
import {ProjectsMap} from "./projects-map";

export interface IProjectsMap {
  filter: IProjectFilter;
}

export const ProjectMapContainer = ({ filter }: IProjectsMap) => {
  const [fetchProjectItems, {
    data: projectMapItems,
    error,
    isLoading,
  }] = useProjectMapLazyFetchAll();

  useEffect(() => {
    fetchProjectItems({
      queryParams: {
        filters: buildRestFilter( { ...filter } ),
      },
    })
  }, [filter])

  if (error) {
    return (
      <Alert
        title={"Daten konnten nicht geladen werden"}
        message={error as string}
      />
    );
  }

  return (
    <ProjectsMap
      projectMapItems={projectMapItems?.data}
      reloading={isLoading}
    />
  );
};
