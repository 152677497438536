import React from "react";
import {formatBigNumber} from "../shared/utils/formatters";

export const CalculatedItem = ({
                                 label,
                                 value1,
                                 value2,
                                 unit1 = "",
                                 unit2 = "",
                                 className,
                                 trailingZeros = true,
                                 singleNumber = false
                               }: {
  label: string,
  value1?: number | null,
  unit1?: string,
  value2?: number | null,
  unit2?: string,
  className?: string,
  trailingZeros?: boolean,
  singleNumber?: boolean
}) => {
    return (
        <div className="flex flex-col">
          <div
            className={"text-sm uppercase text-gray-500 flex flex-row group items-center" + (className ? " " + className : "")}>
            {label}
          </div>
          <div className={`flex rounded-md text-sm ${singleNumber ? "mt-3" : "mt-1"}`}>
            {value1 ? `${formatBigNumber(value1, true, trailingZeros)} ${unit1}` : " - "}<br/>
            {value2 ? `${formatBigNumber(value2, true, trailingZeros)} ${unit2}` : null}
            </div>
        </div>
    )
}
