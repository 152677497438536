// for these, the attributes rentalSituation{}LetArea and rentalSituation{}VacArea exist
export const AREA_ATTRIBUTE_TYPES = ["Office", "Retail", "Storage", "Archive", "Gastro", "Hotel", "Residential", "Leisure", "Social", "BusGen", "MiscArea1", "MiscArea2"];

// for these, the attributes rentalSituation{}LetNumbers and rentalSituation{}VacantNumbers exist
export const NUMBER_ATTRIBUTE_TYPES = ["Hotel", "Social", "Inpark", "Outpark", "Miscnumbers1", "Miscnumbers2"];

// elements from AREA_ATTRIBUTE_TYPES and NUMBER_ATTRIBUTE_TYPES in a convenient order
// (miscellaneous entries at the end)
export let areaAndNumberAttributeTypes = AREA_ATTRIBUTE_TYPES.slice()
areaAndNumberAttributeTypes.splice(-2, 0,
  ...NUMBER_ATTRIBUTE_TYPES.filter(e => !AREA_ATTRIBUTE_TYPES.includes(e)));

export type WertType = "VKW" | "KP"
export const calcWert = (researchAttributes: any, type: WertType) => (
    type === "VKW"
        ? researchAttributes?.marketValue
        : researchAttributes?.priceOfSale
);
export const calcWertPerQm = (researchAttributes: any, type: WertType) => {
    const rentableArea = researchAttributes?.totalRentableArea;
    return rentableArea ? (calcWert(researchAttributes, type) / rentableArea) : 0;
}

export const calcBodenwertPerQm = (researchAttributes: any) => {
    const landSize = researchAttributes?.landSize;
    const landValue = researchAttributes?.landValue
    return landSize ? (landValue / landSize) : 0;
};

export const calcNettoAnfangsrendite = (researchAttributes: any, type: WertType) => {
    const verkehrswert = calcWert(researchAttributes, type);
    const faktorBetriebskosten = researchAttributes?.faktorNichtUmlagefaehigeBetriebskosten || 0;
    const faktorAnschaffungsnebenkosten = researchAttributes?.faktorAnschaffungsnebenkosten || 0;
    return verkehrswert ? ((calcRohertrag(researchAttributes) * (1 - faktorBetriebskosten / 100)) / (verkehrswert * (1 + faktorAnschaffungsnebenkosten / 100))) * 100 : null;
}

export const calcBruttoAnfangsrendite = (researchAttributes: any, type: WertType) => {
    const verkehrswert = calcWert(researchAttributes, type);
    return verkehrswert ? calcRohertrag(researchAttributes) / calcWert(researchAttributes, type) * 100 : 0;
}
export const calcFaktorAktuell = (researchAttributes: any, type: WertType) => {
    const wert = calcWert(researchAttributes, type);
    return wert ? wert / calcRohertrag(researchAttributes) : 0;
};
export const calcFaktorMarkt = (researchAttributes: any, type: WertType) => {
    const wert = calcWert(researchAttributes, type);
    return wert ? wert / calcBewertungsMieteProJahr(researchAttributes) : 0;
};

export const calcVertragsmieteProJahr = (researchAttributes: any) =>
  areaAndNumberAttributeTypes
    .reduce((previous: number, current: string) => {
        return previous + (researchAttributes?.["rentalSituation" + current + "ContractualAnnualRent"] || 0);
    }, 0);

// TODO: make field values consistent (EstAnnRent vs. EstimatedAnnualRent)
export const calcLeerstandsertragProJahr = (researchAttributes: any) => {
    let sum = researchAttributes?.rentalSituationOfficeEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationRetailEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationStorageEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationArchiveEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationGastroEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationResidentialEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationHotelEstimatedAnnualRentVacant || 0;
    sum += researchAttributes?.rentalSituationLeisureEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationSocialEstimatedAnnualRentVacant || 0;
    sum += researchAttributes?.rentalSituationBusGenEstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationInparkEstAnnRentForVacantNumbers || 0;
    sum += researchAttributes?.rentalSituationOutparkEstAnnRentForVacantNumbers || 0;
    sum += researchAttributes?.rentalSituationMiscArea1EstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationMiscArea2EstAnnRentForVacArea || 0;
    sum += researchAttributes?.rentalSituationMiscnumbers1EstAnnRentForVacantNumbers || 0;
    sum += researchAttributes?.rentalSituationMiscnumbers2EstAnnRentForVacantNumbers || 0;
    return sum;
}

export const calcBewertungsMieteProJahr = (researchAttributes: any) => {
    let sum = researchAttributes?.rentalSituationOfficeEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationRetailEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationStorageEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationArchiveEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationGastroEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationResidentialEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationHotelEstimatedAnnualRentLet || 0;
    sum += researchAttributes?.rentalSituationLeisureEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationSocialEstimatedAnnualRentLet || 0;
    sum += researchAttributes?.rentalSituationBusGenEstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationInparkEstAnnRentForLetNumbers || 0;
    sum += researchAttributes?.rentalSituationOutparkEstAnnRentForLetNumbers || 0;
    sum += researchAttributes?.rentalSituationMiscArea1EstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationMiscArea2EstAnnRentForLetArea || 0;
    sum += researchAttributes?.rentalSituationMiscnumbers1EstAnnRentForLetNumbers || 0;
    sum += researchAttributes?.rentalSituationMiscnumbers2EstAnnRentForLetNumbers || 0;
    return sum;
}

export const calcRohertrag = (researchAttributes: any) => calcVertragsmieteProJahr(researchAttributes) + calcLeerstandsertragProJahr(researchAttributes);
export const calcMarktertrag = (researchAttributes: any) => calcBewertungsMieteProJahr(researchAttributes) + calcLeerstandsertragProJahr(researchAttributes);

export const calcCap = (researchAttributes: any) => {
    const faktorBetriebskosten = researchAttributes?.faktorNichtUmlagefaehigeBetriebskosten || 0;
    return ((calcRohertrag(researchAttributes) * (1 - faktorBetriebskosten / 100)) / researchAttributes?.marketValue) * 100;
}

export const calcGesamtmietfläche = (researchAttributes: any) => {
    let sum = 0;
    AREA_ATTRIBUTE_TYPES.forEach((t) => {
        sum += researchAttributes?.[`rentalSituation${t}LetArea`] || 0;
        sum += researchAttributes?.[`rentalSituation${t}VacArea`] || 0;
    });
    return sum;
}
