const NO_DIGITS_BARIER = 1_000_000;
export function formatLargeCurrency(number: number) {
  if (number >= 1_000_000_000) {
    // Round to billions with a three digit precision and display as a string with "Mrd." suffix
    const billionNumber = (number / 1_000_000_000).toFixed(2).replace(".", ",");
    return billionNumber + " Mrd. €";
  } else if (number >= 1_000_000) {
    // Round to millions with a three digit precision and display as a string with "Mio." suffix
    const millionNumber = (number / 1_000_000).toFixed(2).replace(".", ",");
    return millionNumber + " Mio. €";
  } else {
    // Display as a regular number with a two digit precision
    return formatCurrency(number);
  }
}

export const formatCurrency = (val: number | null, barrier: number = NO_DIGITS_BARIER) => {
  if (val === null) {
    return "-";
  }
  if (!val && !Number.isNaN(val)) {
    return "0,00 €";
  }
  const [integer, decimals] = val.toFixed(2).split(".");
  // Functionally add thousand-divider. (reverse, split in units of three, reverse again)
  const strInteger = integer
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    ?.join(".")
    .split("")
    .reverse()
    .join("");
  if (!strInteger) {
    return "0,00 €";
  }
  if (barrier < val) {
    return `${strInteger} €`;
  } else {
    return `${strInteger},${decimals} €`;
  }
};

export const formatBigNumber = (val: number | null, withDecimals = true, trailingZeros = true) => {
  if (val === null) {
    return "-";
  }
  if (!val && !Number.isNaN(val)) {
    return withDecimals && trailingZeros ? "0,00" : "0";
  }
  let [integer, decimals] = val.toFixed(2).split(".");
  // Functionally add thousand-divider. (reverse, split in units of three, reverse again)
  const strInteger = integer
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    ?.join(".")
    .split("")
    .reverse()
    .join("");
  if (!strInteger) {
    return trailingZeros ? "0,00" : "0";
  }
  if (!trailingZeros)
    decimals = decimals.replace(/0+$/, "");
  if (NO_DIGITS_BARIER < parseInt(integer) || !withDecimals || !decimals) {
    return `${strInteger}`;
  } else {
    return `${strInteger},${decimals}`;
  }
};

export const formatRichText = (val: string) => {
  if (val === null) {
    return "Kein Text angegeben";
  }
  const richText = escapeHTML(val).replace(/\n/g, "<br />");
  return <span dangerouslySetInnerHTML={{ __html: richText }}></span>;
};

function escapeHTML(str: string) {
  var p = document.createElement("p");
  p.appendChild(document.createTextNode(str));
  return p.innerHTML;
}
