import dayjs from "dayjs";
import {ArrayParam, BooleanParam, DelimitedNumericArrayParam, StringParam, withDefault} from "use-query-params";

import {
  CUSTOMER_STATUS,
  DATE_FILTER_FROM,
  DATE_FILTER_UNTIL,
  INVOICE_OVERDUE,
  INVOICE_STATUS, PROJECT_LIST_CONTRACTOR_FILTER,
  PROJECT_STATUS,
  SEARCH_TERM,
  SORT_BY
} from "../../components/filters/project-filter";

const SortByParam = withDefault(StringParam, 'project.displayId:desc');
const ProjectStatusParam = withDefault(ArrayParam, []);
const ContractorParam = withDefault(ArrayParam, [] as string[]);
const InvoiceStatusParam = withDefault(ArrayParam, []);
const InvoiceOverdueParam = withDefault(BooleanParam, null);
const CustomerStatusParam = withDefault(DelimitedNumericArrayParam, []);
const DateFromFilterParam = withDefault(StringParam, dayjs().startOf("year").format('YYYY-MM-DD'));
const DateUntilFilterParam = withDefault(StringParam, dayjs().endOf("month").format('YYYY-MM-DD'));

export const INVOICE_QUERY_PARAM_CONFIG = {
  [SORT_BY]: SortByParam,
  [SEARCH_TERM]: StringParam,
  [PROJECT_STATUS]: ProjectStatusParam,
  [PROJECT_LIST_CONTRACTOR_FILTER]: ContractorParam,
  [INVOICE_STATUS]: InvoiceStatusParam,
  [INVOICE_OVERDUE]: InvoiceOverdueParam,
  [CUSTOMER_STATUS]: CustomerStatusParam,
  [DATE_FILTER_FROM]: DateFromFilterParam,
  [DATE_FILTER_UNTIL]: DateUntilFilterParam,
}
