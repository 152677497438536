import React from 'react';
import { ReactNode } from "react";
import { H5, TableP } from '../basics/typography';

export const formatCustomer = (data: any, projectOccurrenceNumber: number = 1, ...rest: any[]): ReactNode => {
  return (<div className='truncate pr-2'>
      <H5>{data?.customerName}{projectOccurrenceNumber > 1 ? ` (${projectOccurrenceNumber})` : ""}</H5>
      <TableP>{data?.buildingAddressStreet} {data?.buildingAddressStreetNumber}, {data?.buildingAddressZip} {data?.buildingAddressCity}</TableP>
    </div>
  );
};
