import React from "react";
import {FilterSelect} from "./filter-select";

export interface ICoAppraiserSelect {
  onChange: (selected: boolean | null) => void;
  value: boolean | null;
  placeholder?: string;
}

export const CoAppraiserSelect = ({onChange, placeholder, value}: ICoAppraiserSelect) => {
  const NG_ITEM = {key: "NG", label: "Nebengutachter"}
  const HG_ITEM = {key: "HG", label: "Hauptgutachter"}

  return <FilterSelect
    placeholder={placeholder}
    selected={value === null ? null : value ? NG_ITEM : HG_ITEM}
    onChange={(val) => {
      switch (val?.key) {
        case 'NG':
          onChange(true);
          break;
        case 'HG':
          onChange(false);
          break;
        default:
          onChange(null)
      }
    }}
    items={[NG_ITEM, HG_ITEM]}
  />;
};
