import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import { useQueryParams } from "use-query-params";
import {
  INVOICE_QUERY_PARAM_CONFIG,
} from "../../../pages/invoicing/query-param-config";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import {buildQueryStringInvoice, Contractor} from "./invoice-table";
import saveAs from "file-saver";
import { classNames } from "../../../shared/utils/class-names";
import { defaultButtonClasses } from "../../basics/buttons-classes";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { h4Classes } from "../../basics/typography-classes";


import {DATE_FILTER_FROM, DATE_FILTER_UNTIL, INVOICE_OVERDUE, SORT_BY} from "../../filters/project-filter";

export const CsvDownloadButton = () => {
  const [projectParams] = useQueryParams(INVOICE_QUERY_PARAM_CONFIG);

  // TODO: Refactor to redux
  async function fetchData(contractor: Contractor) {
    const queryString = buildQueryStringInvoice(
      "",
      true,
      1,
      projectParams[SORT_BY],
      [],
      [contractor],
      [],
      projectParams[INVOICE_OVERDUE],
      [],
      {
        from: dayjs(projectParams[DATE_FILTER_FROM]),
        until: dayjs(projectParams[DATE_FILTER_UNTIL]),
      },
    );
    const metricsResponse = await axios.get(
      `${getLoginUrl()}/api/find-all-projects-with-search-csv?${queryString}`,
      {
        headers: {
          Authorization: "Bearer " + getItemTransparent("JWT"),
        },
      }
    );
    const blob = new Blob(["\ufeff", metricsResponse?.data], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, `export_${contractor}_${projectParams[DATE_FILTER_FROM]}-${projectParams[DATE_FILTER_UNTIL]}.csv`);
  }

  return (
    <div>
      <Menu>
        <Menu.Button
          className={classNames(
            ...defaultButtonClasses,
            "bg-white px-4 w-14 shadow-sm border border-gray-300"
          )}
        >
          <EllipsisHorizontalIcon className="w-5 text-gray-500" />
        </Menu.Button>

        {/* Use the `Transition` component. */}
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute z-10 mt-3 -translate-x-1/2 transform px-4 sm:px-2 font-special text-sm">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 px-2 pb-2 w-40 bg-white">
              <h3 className={classNames(...h4Classes, "mt-3 mb-2 text-center")}>
                CSV herunterladen
              </h3>
              <Menu.Item>
                <button
                  className="block rounded-md p-3 transition duration-150 ease-in-out hover:bg-orange-500 w-full hover:text-white"
                  onClick={async () => {
                    await fetchData("naxis");
                  }}
                >
                  Naxis
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  className="block rounded-md p-3 transition duration-150 ease-in-out hover:bg-orange-500 w-full hover:text-white"
                  onClick={async () => {
                    await fetchData("schraeder");
                  }}
                >
                  Schräder
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  className="block rounded-md p-3 transition duration-150 ease-in-out hover:bg-orange-500 w-full hover:text-white"
                  onClick={async () => {
                    await fetchData("esgium");
                  }}
                >
                  ESGium GmbH
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
