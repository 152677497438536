import {Disclosure} from "@headlessui/react";
import {classNames} from "../../shared/utils/class-names";
import {NotificationBell} from "./notifications/notification-bell";
import {ProfileShort} from "./profiles/profile-short";
import {pathIsActive} from "./header";

export const MobileMenu = ({
  navigation,
  userNavigation,
}: {
  navigation: any,
  userNavigation: any;
}) => {
  const mobileNavigation = [
    ...navigation,
    {
      id: 'notifications',
      name: <div className="text-white flex flex-row">Benachrichtigungen <NotificationBell className='ml-3 w-5 h-5' /> </div>,
      activeString: '/notifications',
      href: '/notifications'
    }
  ]

  return (
    <Disclosure.Panel className="lg:hidden">
      <div className="pt-2 pb-3 space-y-1">
        {mobileNavigation.map((item) => {
          const isActive = pathIsActive(item.activeString)
          return <Disclosure.Button
            key={item.id}
            as="a"
            href={item.href}
            className={classNames(
              isActive
                ? "underline"
                : "",
              "block pl-3 pr-4 py-2 text-base text-white hover:bg-green-300"
            )}
            aria-current={isActive ? "page" : undefined}
          >
            {item.name}
          </Disclosure.Button>
        })}
      </div>
      <div className="pt-4 pb-3 border-t border-gray-200">
        <div className="flex items-center px-4">
          <ProfileShort />
        </div>
        <div className="mt-3 space-y-1">
          {userNavigation.map((item: any) => (
            <Disclosure.Button
              key={item.name}
              as="a"
              href={item.href}
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >
              {item.name}
            </Disclosure.Button>
          ))}
        </div>
      </div>
    </Disclosure.Panel>
  );
};
