import React, { LegacyRef, Ref, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { IKeyValuePair } from "../../shared/utils/entity-utils";
import { useOutsideAlerter } from "../basics/outsideClickHandler";

export interface IStatusSelect {
  items: IKeyValuePair[],
  selected: IKeyValuePair[],
  onChange: (selected: IKeyValuePair[]) => void
}

export const StatusSelect = ({items, selected, onChange }: IStatusSelect) => {
  const dropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(dropdownRef, () => { if(isOpen) { setIsOpen(false); } })

  function isSelected(value: IKeyValuePair) {
    return selected.find((el) => el.key === value.key) ? true : false;
  }

  function handleSelect(value: IKeyValuePair) {
    if (!isSelected(value)) {
      const newItem = items.find((el) => el.key === value.key);
      if(newItem) {
        const selectedItemsUpdated: IKeyValuePair[] = [
          ...selected,
          newItem
        ];
        onChange(selectedItemsUpdated);
      }
    } else {
      handleDeselect(value);
    }
  }

  function handleDeselect(value: IKeyValuePair) {
    const selectedItemsUpdated = selected.filter((el) => el.key !== value.key);
    onChange(selectedItemsUpdated);
  }

  return (
        <Listbox
          as="div"
          ref={ dropdownRef as Ref<any> }
          className="w-full"
          value={selected}
          onChange={(value: any) => handleSelect(value)}
        >
          {({ open }) => (
            <>
              <div className="relative">
                <span className="block rounded-xl shadow-sm" onClick={() => { setIsOpen(!isOpen) }}>
                  <Listbox.Button
                    className="cursor-default relative w-full rounded-xl border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-green focus:border-green-500 transition ease-in-out duration-150 sm:leading-5 truncate"
                  >
                    <span>
                      {selected.length < 1
                        ? <span className="text-gray-500 truncate">Status</span>
                        : selected.map(s => s.label).join(", ")}
                    </span>
                    {
                    selected.length > 0
                      ? <span
                        className="absolute inset-y-0 right-6 flex items-center pr-2 pointer-events-auto text-gray-400"
                        onClick={() => { onChange([])}}
                        >
                        <XCircleIcon className='h-5 m-1 cursor-pointer' />
                      </span>
                      : null
                    }
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-gray-400">
                      <ChevronDownIcon className='h-6' />
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  unmount={false}
                  show={isOpen}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 min-w-full rounded-xl bg-white shadow-lg z-[1001]"
                >
                  <Listbox.Options
                    static
                    className="rounded-xl py-1 text-base leading-6 shadow-sm overflow-auto focus:outline-none text-gray-600 z-[1001]"
                  >
                    {items.map((item) => {
                      const selected = isSelected(item);
                      return (
                        <Listbox.Option key={item.key} value={item}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? "text-white bg-green-500"
                                  : "text-gray-900"
                              } cursor-default select-none relative py-2 pl-10 pr-4`}
                            >
                              <span
                                className={`${
                                  selected ? "font-semibold" : "font-normal"
                                } block truncate ml-5`}
                              >
                                {item.label}
                              </span>
                              <span
                                className={`${
                                  active ? "text-white" : "text-green-500"
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <input
                                  id="comments"
                                  aria-describedby="comments-description"
                                  name="comments"
                                  type="checkbox"
                                  readOnly
                                  className="focus:ring-green-500 h-4 w-4 ml-4 text-green-500 border-gray-200 rounded"
                                  checked={selected}
                                />
                              </span>
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
  );
}
