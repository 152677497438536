import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import {
  ADDRESS,
  LOCATION_LAT,
  LOCATION_LNG,
  LOCATION_RANGE,
  MANUAL_LOCATION_RANGE
} from "../../components/filters/project-filter";
import { SearchBox } from "../../components/basics/search-box";
import { useQueryParams } from "use-query-params";
import { researchQueryParams } from "./research-query-params";
import { AddressDropdown } from "./address-dropdown";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectEditing, toggleEditing } from "./projects-polygon-picker-map/layer/polygon-picker-slice";

export const AddressSearch = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [query, setQuery] = useState("");
  const [filterQueryParams, setFilterQueryParams] = useQueryParams(researchQueryParams);
  const dispatch = useAppDispatch();
  const polygonEditing = useAppSelector(selectEditing);

  return (
    <>
      <Popover>
        <SearchBox
          defaultValue={filterQueryParams[ADDRESS]}
          onChange={(val) => {
            if (val) {
              setIsShowing(true);
            }
            setQuery(val);
          }}
          onCancel={() => {
            setFilterQueryParams({
              [ADDRESS]: ""
            })
          }}
          className="text-gray-400 text-base"
          placeholder="Adresse suchen"
        />
        {isShowing && (
          <Popover.Panel
            static
            className="absolute z-[10010] left-1/2 transform -translate-x-2/3 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-md"
          >
            <AddressDropdown query={query} onSelect={(addressSearchResult) => {
              if (!filterQueryParams[MANUAL_LOCATION_RANGE]) {
                setFilterQueryParams({
                  [LOCATION_RANGE]: researchQueryParams[LOCATION_RANGE].default,
                  [MANUAL_LOCATION_RANGE]: true,
                });
              }
              setFilterQueryParams({
                [ADDRESS]: Object.keys(addressSearchResult)?.[0],
                [LOCATION_LAT]: Object.values(addressSearchResult)?.[0].lat,
                [LOCATION_LNG]: Object.values(addressSearchResult)?.[0].lng,
              })
              setIsShowing(false);
              if(polygonEditing) {
                dispatch(toggleEditing());
              }
            }} />
          </Popover.Panel>
        )}
      </Popover>
    </>
  );
};
