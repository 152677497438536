import React from 'react';
import {classNames} from "../../../shared/utils/class-names";
import {h4Classes} from "../../../components/basics/typography-classes";
import {AREA_ATTRIBUTE_TYPES, areaAndNumberAttributeTypes, NUMBER_ATTRIBUTE_TYPES} from "./research-calc";
import valXmlTranslations from "./ValXML-translations.json";
import {MagicUpdate} from "../../../components/magic-update/magic-update";
import {CalculatedItem} from "../../../components/calculated-item";
import {Accordion} from "../../../components/layout/basics/accordion";

const ResearchAccordionLetArea = ({projectResearchId, refetch, researchAttributes}: {
  projectResearchId: string,
  refetch: () => any,
  researchAttributes: any
}) => {
  return (
    <Accordion
      header={<h4 className={classNames(...h4Classes)}>Verteilung der Mietfläche</h4>}
      bgColorClasses="bg-gray-50"
      defaultOpen={false}
    >
      <div className="bg-white p-3 rounded-lg">
        {areaAndNumberAttributeTypes.map((attributeType: string) =>
          <>
            {AREA_ATTRIBUTE_TYPES.includes(attributeType) &&
                <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
                    <h4 className={classNames(...h4Classes, "mb-2")}>
                      {/* @ts-expect-error */}
                      {valXmlTranslations[`rentalSituation${attributeType}LetArea`] || `Fläche ${attributeType}`}
                    </h4>
                    <MagicUpdate
                        model="projectResearch"
                        id={projectResearchId}
                        key={`${attributeType}|letArea`}
                        reload={refetch}
                        type={"Number"}
                        gqlType={"Float"}
                        field={`rentalSituation${attributeType}LetArea`}
                        label={"vermietete Fläche"}
                        value={researchAttributes?.[`rentalSituation${attributeType}LetArea`]}
                    />
                    <MagicUpdate
                        model="projectResearch"
                        id={projectResearchId}
                        key={`${attributeType}|vacArea`}
                        reload={refetch}
                        type={"Number"}
                        gqlType={"Float"}
                        field={`rentalSituation${attributeType}VacArea`}
                        label={"leerstehende Fläche"}
                        value={researchAttributes?.[`rentalSituation${attributeType}VacArea`]}
                    />
                    <CalculatedItem
                        label={"Gesamtfläche"}
                        value1={researchAttributes?.[`rentalSituation${attributeType}LetArea`] + researchAttributes?.[`rentalSituation${attributeType}VacArea`]}
                        trailingZeros={false}
                        singleNumber={true}
                    />
                </div>}
            {NUMBER_ATTRIBUTE_TYPES.includes(attributeType) &&
                <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
                    <h4 className={classNames(...h4Classes, "mb-2")}>
                      {/* @ts-expect-error */}
                      {valXmlTranslations[`rentalSituation${attributeType}LetNumbers`] || `Stückzahl ${attributeType}`}
                    </h4>
                    <MagicUpdate
                        model="projectResearch"
                        id={projectResearchId}
                        key={`${attributeType}|letNumber`}
                        reload={refetch}
                        type={"Number"}
                        gqlType={"Float"}
                        field={`rentalSituation${attributeType}LetNumbers`}
                        label={"Stückzahl vermietet"}
                        value={researchAttributes?.[`rentalSituation${attributeType}LetNumbers`]}
                    />
                    <MagicUpdate
                        model="projectResearch"
                        id={projectResearchId}
                        key={`${attributeType}|vacNumber`}
                        reload={refetch}
                        type={"Number"}
                        gqlType={"Float"}
                        field={`rentalSituation${attributeType}VacantNumbers`}
                        label={"Stückzahl Leerstand"}
                        value={researchAttributes?.[`rentalSituation${attributeType}VacantNumbers`]}
                    />
                    <CalculatedItem
                        label={"Stückzahl gesamt"}
                        value1={researchAttributes?.[`rentalSituation${attributeType}LetNumbers`] + researchAttributes?.[`rentalSituation${attributeType}VacantNumbers`]}
                        trailingZeros={false}
                        singleNumber={true}
                    />
                </div>}
          </>
        )}
      </div>
    </Accordion>
  );
};

export default ResearchAccordionLetArea;
