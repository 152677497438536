import React, {ReactNode} from 'react';
import {Disclosure, Transition} from '@headlessui/react';
import {Card} from '../../basics/card';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/outline';

export const Accordion = ({header, children, bgColorClasses, defaultOpen = true}: {
  header: ReactNode,
  children: ReactNode,
  bgColorClasses: string,
  defaultOpen?: boolean
}) => {
    return (
        <Card className={bgColorClasses}>
          <Disclosure as="div" defaultOpen={defaultOpen}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="w-full mb-3">
                            <div className="flex flex-row justify-between w-full">
                                {header}
                                {open ? <ChevronUpIcon className="w-6 h-6" /> : <ChevronDownIcon className="w-6 h-6"/>}
                            </div>
                        </Disclosure.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform opacity-0"
                            enterTo="transform opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform opacity-100"
                            leaveTo="transform opacity-0"
                        >
                            <Disclosure.Panel>
                                {children}
                            </Disclosure.Panel>
                        </Transition></>)
                }
            </Disclosure>
        </Card>
    )
}
