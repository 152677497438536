import React from "react";
import {FilterSelect} from "./filter-select";

export interface IOverdueSelect {
  onChange: (selected: boolean | null) => void;
  value: boolean | null;
  placeholder?: string;
}

export const OverdueSelect = ({onChange, placeholder, value}: IOverdueSelect) => {
  const OVERDUE_ITEM = {key: "od", label: "überfällig"};

  return <FilterSelect
    placeholder={placeholder}
    selected={value ? OVERDUE_ITEM : null}
    onChange={selected => selected ? onChange(true) : onChange(null)}
    items={[OVERDUE_ITEM]}
  />;
};
