import React, {useEffect} from "react";
import {useFetchUsersQuery} from "../../model/users/user-api";
import {ProfilePicture} from "../../components/layout/profiles/profile-picture";
import {CheckBadgeIcon, XCircleIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {buttonClasses, defaultButtonClasses, primaryButtonClasses} from "../../components/basics/buttons-classes";
import {classNames} from "../../shared/utils/class-names";
import {Link, Outlet, useLocation} from "react-router-dom";

export const UsersList = () => {
    const location = useLocation();
    const { data: people, error: usersError, isFetching, refetch } =
      useFetchUsersQuery({
        queryParams: {
          populate: ["image", "role"],
          sort: {createdAt: 'desc'}
        }
      });

    useEffect(() => { refetch() },[location.key])

    return (
        <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="mt-4 sm:items-end">
            <Link
              to="/users/new"
              type="button"
              className={classNames(...primaryButtonClasses)}>
              Neuen Nutzer anlegen
            </Link>
          </div>
        </div>
        <div className="mt-8 flow-root px-4 pt-2 bg-white border border-gray-300 rounded-2xl">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900">
                      Rolle
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900">
                      Bearbeiter?
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900">
                      Gutachter?
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Bearbeiten</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people?.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            { person.blocked 
                              ? <XCircleIcon className="w-8 h-8 stroke-gray-600" /> 
                              : <ProfilePicture profilePicturePath={person?.image?.url} />}
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{person?.username}</div>
                            <div className="mt-1 text-gray-500">{person?.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-gray-500">
                        <div className="text-gray-900">{person?.role?.name}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-gray-500">
                        {person?.isEditor ?
                          <CheckBadgeIcon className="w-6 text-green" /> :
                          <XMarkIcon className="w-6 text-gray" />
                        }
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-gray-500">
                        {person?.isAppraiser ?
                          <CheckBadgeIcon className="w-6 text-green" /> :
                          <XMarkIcon className="w-6 text-gray" />
                        }
                      </td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right font-medium sm:pr-0">
                        <Link to={person.blocked ? "#" : `/users/${person?.id}`} className={classNames(...buttonClasses, person.blocked ? "bg-gray-50": "bg-green-500", "text-white")}>
                          Bearbeiten<span className="sr-only">, {person.name}</span>
                        </Link>
                        <Link to={person.blocked ? "#" : `/users/${person?.id}/delete`} className={classNames(...buttonClasses, person.blocked ? "bg-red-100": "bg-red-600", "ml-2 text-white")}>
                          Deaktivieren <span className="sr-only">, {person.name}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    )
}
