import React from "react";
import {useQueryParams} from "use-query-params";
import {ProjectListWrapper} from "../../../components/project-list/project-list-wrapper";
import {ProjectListFilterBar} from "../project-list-filter-bar";
import {mapQueryParamsToFilter, projectListQueryParams,} from "../project-list-query-params";
import {DIRECTION, SORT_BY} from "../../../components/filters/project-filter";
import {ProjectListItem} from "../../../components/project-list/project-list-item";
import {PROJECTS_QUERY} from "../../../components/project-list/project-utils";

export const ProjectListViewCards = () => {
  const [searchParams] = useQueryParams(projectListQueryParams);

  return (
    <>
        <ProjectListFilterBar />
        <div className="border-2 border-gray-300 rounded-xl items-center pt-4 px-4">
        <ProjectListWrapper
          filter={mapQueryParamsToFilter(searchParams)}
          sortBy={{
            [SORT_BY]: searchParams[SORT_BY],
            [DIRECTION]: searchParams[DIRECTION],
          }}
          ListItem={ProjectListItem}
          query={PROJECTS_QUERY}
          showLoadMore
        />
      </div>
    </>
  );
};
