import { projectTypeMap } from "../../../common/project-type-map";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Tick,
  defaults,
} from "chart.js";
import {
  AnalyticsEmployeeChartResult,
  AnalyticsEmployeeResult,
  Employee,
} from "../../../model/analytics/employees/analytics-employees";
import React, { CSSProperties, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { AuthImg } from "../../../components/layout/auth-img";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import { classNames } from "../../../shared/utils/class-names";
import { DummyBarChart } from "./dummy-bar-chart";
import { renderBarChartTooltip } from "./line-chart/chart-tooltip";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatCurrency } from "../../../shared/utils/formatters";

const totalizer = {
  id: "totalizer",

  beforeUpdate: (chart: any) => {
    let totals: { [key: number]: any } = {};
    let utmost = 0;

    chart.data.datasets.forEach((dataset: any, datasetIndex: any) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        utmost = datasetIndex;
        dataset.data.forEach((value: any, index: number) => {
          totals[index] = (totals[index] || 0) + value;
        });
      }
    });

    chart.$totalizer = {
      totals: totals,
      utmost: utmost,
    };
  },
};

export type IEmployeeDatasetMap = {
  [key: string]: AnalyticsEmployeeChartResult[];
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  totalizer
);

defaults!!.plugins!!.datalabels!!.display = false;

function convertToChartResult(
  fetchedData: AnalyticsEmployeeResult,
  employees: Employee[],
  years: number[]
): IEmployeeDatasetMap {
  const projectTypes = fetchedData?.employees?.reduce((acc: string[], item) => {
    if (!acc.includes(item.projectType)) {
      acc.push(item.projectType);
    }
    return acc;
  }, []);

  return employees.reduce((acc: IEmployeeDatasetMap, employee) => {
    acc[employee?.id] = [
      ...projectTypes.flatMap((pt) => {
        return {
          datasetLabel: pt,
          sums: years.map(
            (y) =>
              fetchedData?.employees?.find(
                (fe) =>
                  fe.user_id === parseInt(employee?.id) &&
                  fe.projectType === pt &&
                  fe.year === y
              )?.sum || 0
          ),
        };
      }),
    ];
    return acc;
  }, {});
}

export const options = {
  indexAxis: "y" as const,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: true,
    mode: "nearest" as const,
    xAlign: "center",
    yAlign: "bottom",
  },
  plugins: {
    tooltip: {
      enabled: false,
      external: renderBarChartTooltip,
    },
    legend: {
      display: false,
      onClick: () => {},
      labels: {
        font: {
          family: "Roboto",
        },
        useBorderRadius: true,
        borderRadius: 5,
        usePointStyle: true,
        pointStyle: "rectRounded" as const,
        boxWidth: 50,
      },
      align: "start" as const,
    },
    title: {
      display: false,
    },
    datalabels: {
      formatter: (value: any, ctx: any) => {
        const total = ctx.chart.$totalizer.totals[ctx.dataIndex];
        return formatCurrency(total);
      },
      font: {
        family: "Poppins" as const,
        weight: "bold" as const,
        size: 14 as const,
      },
      color: "rgb(33,33,33" as const,
      align: "end" as const,
      anchor: "end" as const,
      display: function (ctx: any) {
        return ctx.datasetIndex === ctx.chart.$totalizer.utmost;
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          family: "Roboto",
        },
        display: false,
        callback: function (value: any, index: number, ticks: Tick[]) {
          return formatCurrency(value);
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          family: "Roboto",
        },
      },
      grid: {
        display: false,
      },
    },
  },
};

export interface IEmployeeChartBarsProps {
  data: AnalyticsEmployeeResult;
  selectedEmployees: Employee[];
  years: number[];
  styles: CSSProperties;
}

function calculateMax(
  data: AnalyticsEmployeeResult,
  years: number[],
  employees: Employee[]
) {
  const yearMaxs = years.reduce((acc: number[], y: number) => {
    const sumEmployeeYear = employees?.map((e) => {
      return data?.employees
        ?.filter(
          (dataItem) =>
            dataItem.user_id === parseInt(e.id) && dataItem.year === y
        )
        .reduce((acc, dataItem) => acc + dataItem.sum, 0);
    }, []);
    return [...acc, Math.max(...sumEmployeeYear)];
  }, []);
  return Math.max(...yearMaxs) * 1.05;
}

export const EmployeeChartBars = ({
  data: fetchedData,
  selectedEmployees,
  years,
  styles,
}: IEmployeeChartBarsProps) => {
  const [data, setData] = useState<IEmployeeDatasetMap | null>(null);
  const [max, setMax] = useState<number | null>(null);

  useEffect(() => {
    if (fetchedData) {
      if (selectedEmployees && selectedEmployees?.length > 0) {
        setMax(calculateMax(fetchedData, years, selectedEmployees));
        setData(
          convertToChartResult(
            fetchedData,
            selectedEmployees,
            years as number[]
          )
        );
      }
    }
  }, [selectedEmployees, fetchedData]);

  return (
    <div className="p-6">
      {selectedEmployees?.map((employee, index) => {
        if (data) {
          const labels = years;
          const chartData = {
            labels,
            datasets: data?.[employee.id]?.map((e) => {
              return {
                label: `${e.datasetLabel}`,
                data: e.sums,
                backgroundColor: projectTypeMap[e.datasetLabel]?.color,
              };
            }),
          };
          return (
            <div key={employee.id} className="flex md:flex-row flex-wrap">
              <div className="md:w-1/12 w-full">
                <AuthImg
                  className="h-12 w-12 rounded-full"
                  src={`${getLoginUrl()}${
                    employee.attributes.image?.data?.attributes?.url
                  }`}
                  size={"thumbnail"}
                />
              </div>
              <div
                className={classNames(
                  "md:w-11/12 w-full h-40",
                  index === selectedEmployees.length - 1 ? "" : "pr-9"
                )}
              >
                {chartData?.datasets ? (
                  <Bar
                    options={{
                      ...options,
                      plugins: {
                        ...options.plugins,
                      },
                      scales: {
                        ...options.scales,
                        x: {
                          ...options.scales.x,
                          suggestedMax: max || 0,
                          ticks: {
                            ...options.scales.x.ticks,
                            display: index === selectedEmployees.length - 1,
                          },
                        },
                      },
                    }}
                    data={chartData}
                    style={styles}
                  />
                ) : null}
              </div>
            </div>
          );
        } else {
          return (
            <DummyBarChart key={employee.id} option={options} styles={styles} />
          );
        }
      })}
      <div className="flex flex-row mt-3 ml-10">
      <div className="w-1/12"></div>
      <div className="w-11/12">
        <div className="grid grid-cols-6 gap-2">
          {Object.keys(projectTypeMap).map((type: string) => {
            return (
              <div key={type} className="text-sm text-gray-600 truncate">
                <div
                  className="rounded-md w-5 h-4 float-left mr-2"
                  style={{ backgroundColor: projectTypeMap[type]?.color }}
                >
                  {" "}
                </div>
                {projectTypeMap[type]?.displayString}
              </div>
            );
          })}
        </div>
      </div>
      </div>
    </div>
  );
};
