import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Header} from "./components/layout/header";
import {ProfileContextProvider} from "./components/profile-context-provider/profile-context-provider";
import {createApolloClient} from "./config/apollo-client";
import CustomerRoute from "./pages/customers";
import UserManagementRoute from "./pages/user-management";

import Home from "./pages/home/home";
import InvoiceRoute from "./pages/invoicing";
import Notifications from "./pages/notifications";
import {Project} from "./pages/project/project";
import {ProjectList} from "./pages/project-list/project-list";
import ProjectListMobile from "./pages/project-list/project-list-mobile";
import {getItemTransparent} from "./shared/utils/storage-utils";
import {EmployeeAnalytics} from "./pages/analytics/employees/employees";
import {ReferenceAnalytics} from "./pages/analytics/references/references";
import Alert from "./components/layout/alert";
import {ProfileEdit} from "./components/layout/profiles/profile-edit";
import {ResearchLight} from "./pages/research/research-light";
import ErrorBoundary from "./shared/error/error-boundary";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [apolloClient, setApolloClient] = useState({});

  useEffect(() => {
    const isAuthenticated = getItemTransparent("JWT");
    if (!isAuthenticated) {
      navigate("/login?redirectTo=" + location.pathname + location.search);
    } else {
      if (Object.keys(apolloClient).length === 0) {
        setApolloClient(createApolloClient());
      }
    }
  }, [apolloClient, location.pathname, location.search, navigate]);

  return (
    <>
      {Object.keys(apolloClient).length === 0 ? null : (
        <ApolloProvider client={apolloClient as ApolloClient<InMemoryCache>}>
          <div className="min-h-screen">
            <ProfileContextProvider>
            <Header />

            <div className="pb-10">
              <main>
                <div className="max-w-screen-2xl mx-auto sm:px-6 lg:px-8">
                  <div className="px-4 py-8 sm:px-0">
                    <ErrorBoundary>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/notifications" element={<Notifications />} />
                        <Route path="/project-list-mobile" element={<ProjectListMobile />} />
                        <Route path="/project-list/*" element={<ProjectList parentPath="/project-list" />} />
                        <Route path="/research/*" element={<ResearchLight/>} />
                        <Route path="/analytics/employees/*" element={<EmployeeAnalytics parentPath="/analytics/employees" />} />
                        <Route path="/analytics/references/*" element={<ReferenceAnalytics parentPath="/analytics/references" />} />
                        <Route path="/projects/:id/*" element={<Project parentPath='/projects/:id' />} />
                        <Route path="/customers/*" element={<CustomerRoute />} />
                        <Route path="/users/*" element={<UserManagementRoute />} />
                        <Route path="/invoices/*" element={<InvoiceRoute />} />
                        <Route path="/me" element={<ProfileEdit />} />
                        <Route path="*" element={(<Alert severity="info" title="Seite nicht gefunden" message="Die Seite, die Sie versucht haben aufzurufen, existiert nicht" />)} />
                      </Routes>
                    </ErrorBoundary>
                  </div>
                </div>
              </main>
            </div>
            </ProfileContextProvider>
          </div>
        </ApolloProvider>
      )}
    </>
  );
};

export default PrivateRoutes;
