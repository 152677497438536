import React from "react";
import {Disclosure} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {ProfileDropdown} from "./profiles/profile-dropdown";
import {classNames} from "../../shared/utils/class-names";
import {MobileMenu} from "./mobile-menu";
import {logout} from "../../shared/auth/authentication";
import {PlusSmallIcon} from "@heroicons/react/20/solid";
import NotificationDropdown from "./notification-dropdown";
import {Search} from "./search/search";
import {Link, matchPath} from "react-router-dom";
import {NewProjectModal} from "./new-project/new-project-modal";
import {getItemTransparent} from "../../shared/utils/storage-utils";
import {open as openNewProjectModal} from '../layout/new-project/new-project-reducer';
import SubMenu from "../header-sub-menu";
import {useDispatch} from "react-redux";

const navigation = [
  {
    id: "dashboard",
    name: "Dashboard",
    href: "/",
    activeString: "/",
  },
  {
    id: "projects",
    name: "Aufträge",
    href: "/project-list/cards",
    activeString: ["/project-list/*", "/projects/*"],
  },
  {
    id: "research",
    name: "Research",
    href: "/research?sortBy=deadlineDate",
    activeString: "/research/*",
  },
  {
    id: "invoices",
    name: "Rechnungen",
    href: "/invoices",
    activeString: "/invoices/*",
    roles: ["geschaftsfuhrer", "buchhalter", "superadmin", "admin"],
  },
  {
    id: "analytics",
    name: "Auswertungen",
    submenuItems: [{
      name: "Mitarbeiter",
      href: "/analytics/employees/table",
      activeString: "/analytics/employees/*"
    },
    {
      name: "Referenzen",
      href: "/analytics/references/table",
      activeString: "/analytics/references/*"
    }],
    activeString: "/analytics/*",
    roles: ["geschaftsfuhrer", "superadmin"],
  },
];

const userNavigation = [
  {
    id: "customers",
    name: "Profil bearbeiten",
    href: "/me",
    activeString: "/me",
  },
  {
    id: "customers",
    name: "Stammkunden",
    href: "/customers",
    activeString: "/customers/*",
    roles: ["geschaftsfuhrer", "superadmin", "admin"],
  },
  {
    id: "users",
    name: "Nutzerverwaltung",
    href: "/users",
    activeString: "/users/*",
    roles: ["geschaftsfuhrer", "superadmin", "admin"],
  },
  {
    name: "Abmelden",
    href: "",
    action: (e: MouseEvent) => {
      e.preventDefault();
      logout();
    },
  },
];

export const pathIsActive = (activeString: string | string[]) => {
  let isActive = false;
  if (typeof activeString === "string") {
    isActive = !!matchPath(
      activeString,
      location.pathname
    );
  } else {
    for (const s of activeString) {
      if (matchPath(s, location.pathname)) {
        isActive = true;
        break;
      }
    }
  }
  return isActive
}

export const Header = () => {
  const dispatch = useDispatch();

  const user = getItemTransparent("user");
  const filteredNavigation = navigation.filter(
    (item) =>
      !item?.roles || item?.roles?.includes(user.role?.type)
  )

  return (
    <Disclosure as="nav" className="bg-green-500">
      {({ open }) => (
        <>
          <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 font-special">
            <div className="flex justify-between h-16">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block lg:hidden h-10 w-auto"
                  src="/logo_white.png"
                  alt="Logo Schräder"
                />
                <img
                  className="hidden lg:block h-10 w-auto"
                  src="/logo_white.png"
                  alt="Logo Schräder"
                />
              </div>
              <div className="flex">
                <div className="hidden lg:-my-px lg:ml-6 lg:flex 2xl:space-x-8 lg:space-x-2">
                  {filteredNavigation
                    .map((item) => {
                      const isActive = pathIsActive(item.activeString)
                      return item.href ? (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            isActive
                              ? "border-orange-500 text-orange-500"
                              : "border-transparent text-white hover:border-gray-100 hover:text-gray-100",
                            "inline-flex items-center px-1 pt-1 border-b-4 font-medium"
                          )}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <SubMenu
                          key={item.name}
                          name={item.name}
                          subMenuItems={item.submenuItems!!}
                          mainActive={isActive}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="hidden lg:ml-6 lg:flex lg:items-center">
                {["superadmin", "geschaftsfuhrer", "admin"].includes(
                  user.role?.type
                ) ? (
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(openNewProjectModal());
                    }}
                    title="Neuen Auftrag erstellen"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white bg-orange-500 hover:bg-orange-600 mr-4"
                  >
                    <PlusSmallIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Neuer Auftrag</span>
                  </button>
                ) : null}
                <Search />
                <NotificationDropdown />
                <ProfileDropdown userNavigation={userNavigation} user={user} />
              </div>
              <div className="-mr-2 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
            <NewProjectModal />
          </div>
          <MobileMenu navigation={filteredNavigation} userNavigation={userNavigation}/>
        </>
      )}
    </Disclosure>
  );
};
