import React from "react";
import { dateFormatter } from "../../../shared/utils/date-utils";
import { BuildingType } from "../building-type";
import { ProjectStatus } from "../project-status";
import { HistoryMarker } from "../../history-marker";

export const SearchDropdownItem = ({ project }: any) => {
  return (
    <div key={project.id} className="w-full">
      <span className="absolute inset-0" aria-hidden="true" />
      <div className="flex flex-row items-center mb-2">
        {project?.successor || project?.predecessor ? <HistoryMarker className="w-8 h-[1.85em] mr-1 float-left rounded" /> : null}
        <ProjectStatus status={project.status} />
        <BuildingType type={project.building?.type} />
      </div>
      <p className="text font-semibold text-gray-700 mb-2">
        {project?.building?.name}
      </p>
      <div className="w-full flex flex-row justify-between font-sans">
        <div className="text-sm text-gray-500">
          <strong className='font-semibold'>{project?.displayId},</strong> <span className="uppercase">{project?.customer}</span>
        </div>
        <div className="text-sm text-gray-500">
          <strong className='font-semibold'>Stichtag:</strong> {dateFormatter(project?.deadlineDate)}
        </div>
      </div>
    </div>
  );
};
