import { Icon, LatLng, latLng, point } from "leaflet";
import { useCallback, useEffect } from "react";
import {Marker, Popup, useMap, useMapEvent} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Link } from "react-router-dom";
import { ProjectMap } from "../../../model/project-map/project-map-model";
import { MapMarkerNumberItem } from "./layer/map-marker-number-item";
import { selectEditing } from "./layer/polygon-picker-slice";
import { useAppSelector } from "../../../hooks";
import {
  ADDRESS,
  LOCATION_LAT,
  LOCATION_LNG,
  LOCATION_RANGE,
  MANUAL_LOCATION_RANGE
} from "../../../components/filters/project-filter";
import {useQueryParams} from "use-query-params";
import {researchQueryParams} from "../research-query-params";
import { calcBoundingBox } from "../../project-list/project-map/map-layers/building-map-layer";

export const ResearchMapLayer = ({
  clusterIcons = true,
  clusterRadius,
  projectMapItems = [],
  fitBoundingBox = true,
}: {
  clusterIcons: boolean,
  clusterRadius: number;
  projectMapItems: ProjectMap[];
  fitBoundingBox?: boolean;
}) => {
  const map = useMap();
  const editing = useAppSelector(selectEditing);
  const [filterQueryParams, setFilterQueryParams] = useQueryParams(researchQueryParams);

  const lazyReload = useCallback(() => {
    const loadedBounds = new LatLng(filterQueryParams[LOCATION_LAT], filterQueryParams[LOCATION_LNG])
      .toBounds(filterQueryParams[LOCATION_RANGE] * 1000);
    if (!loadedBounds.contains(map.getBounds()) && !filterQueryParams[ADDRESS]) {
      // set a new filter query to trigger a reload
      const mapCenter = map.getCenter();
      setFilterQueryParams({
        [LOCATION_LAT]: mapCenter.lat,
        [LOCATION_LNG]: mapCenter.lng,
        // load some more items than necessary (factor 4)
        [LOCATION_RANGE]: map.distance(map.getBounds().getNorthWest(), mapCenter) * 4 / 1000,
        [MANUAL_LOCATION_RANGE]: false,
      });
    }
  }, [filterQueryParams, map, setFilterQueryParams]);

  // whenever the map moved or address search gets disabled (-> fitBoundingBox === false), call lazyReload()
  useMapEvent("moveend", () => lazyReload());
  useEffect(() => {
    if (!fitBoundingBox) lazyReload();
  }, [fitBoundingBox, lazyReload]);

  useEffect(() => {
    if (!editing && fitBoundingBox) {
      map.fitBounds(calcBoundingBox(projectMapItems));
    }
  }, [projectMapItems, map, editing, fitBoundingBox]);

  return (
    // The random key is needed to force rerender on changed map config (problem with the library)
    <MarkerClusterGroup key={Math.random().toFixed(2)} maxClusterRadius={clusterRadius} disableClusteringAtZoom={ clusterIcons ? null : 1 } >
      {Array.from(projectMapItems)?.map((a: ProjectMap) =>
        a.lat && a.lng ? (
          <Marker
            key={a?.projectId}
            position={latLng(a.lat, a.lng)}
            icon={
              new Icon({
                iconUrl: `/icons/map_${a?.objectType}.svg`,
                iconSize: [30, 30],
                iconAnchor: [15, 15],
              })
            }
            zIndexOffset={2000}
          >
            <Popup
              autoPan
              position={latLng(a.lat, a.lng)}
              offset={point([0, 0])}
              closeButton={false}
              maxWidth={380}
              maxHeight={475}
            >
              <Link to={`/projects/${a?.projectId}`} target="_blank">
                <MapMarkerNumberItem projectId={a?.projectId} />
              </Link>
            </Popup>
          </Marker>
        ) : null
      )}
    </MarkerClusterGroup>
  );
};
