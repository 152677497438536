import {
  ArrayParam,
  BooleanParam,
  DateParam,
  DecodedValueMap,
  NumberParam,
  NumericArrayParam,
  StringParam,
  withDefault
} from "use-query-params";
import {
  ADDRESS,
  BUILDING_TYPES_1,
  BUILDING_TYPES_2,
  DIRECTION,
  IProjectFilter,
  LOCATION_LAT,
  LOCATION_LNG,
  LOCATION_RANGE,
  MANUAL_LOCATION_RANGE,
  PROJECT_LIST_DEADLINE_DATE_END,
  PROJECT_LIST_DEADLINE_DATE_START,
  PROJECT_LIST_TYPE_FILTER,
  PROJECT_RESEARCH_VALUATION,
  PROJECT_RESEARCH_YEAR_RANGE,
  SORT_BY,
} from "../../components/filters/project-filter";
import {LatLng, LatLngBounds} from "leaflet";

export const SortByParam = withDefault(StringParam, 'deadlineDate');
export const DirectionParam = withDefault(StringParam, 'desc');

const ProjectTypeParam = withDefault(ArrayParam, [] as string []);
const ResearchObjectTypeParam = withDefault(ArrayParam, [] as string[]);
const LocationLatParam = withDefault(NumberParam, 50.93635);
const LocationLngParam = withDefault(NumberParam, 6.961501);
const LocationRangeParam = withDefault(NumberParam, 1);
const FilterDateParam = withDefault(DateParam, null);
const NumberRangeParam = withDefault(NumericArrayParam, null);
const ManualLocationRangeParam = withDefault(BooleanParam, true);


export const researchQueryParams = {
  [SORT_BY]: SortByParam,
  [DIRECTION]: DirectionParam,
  [ADDRESS]: StringParam,
  [PROJECT_LIST_TYPE_FILTER]: ProjectTypeParam,
  [BUILDING_TYPES_1]: ResearchObjectTypeParam,
  [BUILDING_TYPES_2]: ResearchObjectTypeParam,
  [PROJECT_LIST_DEADLINE_DATE_START]: FilterDateParam,
  [PROJECT_LIST_DEADLINE_DATE_END]: FilterDateParam,
  [PROJECT_RESEARCH_YEAR_RANGE]: NumberRangeParam,
  [PROJECT_RESEARCH_VALUATION]: StringParam,
  [LOCATION_LAT]: LocationLatParam,
  [LOCATION_LNG]: LocationLngParam,
  [LOCATION_RANGE]: LocationRangeParam,
  [MANUAL_LOCATION_RANGE]: ManualLocationRangeParam,
};

export function mapQueryParamsToFilter(filterQueryParams: DecodedValueMap<typeof researchQueryParams>, geoBounds: LatLngBounds | undefined = undefined): IProjectFilter {
  if (!geoBounds && filterQueryParams[LOCATION_LAT] && filterQueryParams[LOCATION_LNG]) {
    geoBounds = new LatLng(filterQueryParams[LOCATION_LAT], filterQueryParams[LOCATION_LNG])
      .toBounds(filterQueryParams[LOCATION_RANGE] * 1000 * 2);
  }

  return {
    projectTypes: filterQueryParams[PROJECT_LIST_TYPE_FILTER],
    buildingTypes1: filterQueryParams[BUILDING_TYPES_1],
    buildingTypes2: filterQueryParams[BUILDING_TYPES_2],
    locationLat: filterQueryParams[LOCATION_LAT],
    locationLng: filterQueryParams[LOCATION_LNG],
    locationRange: filterQueryParams[LOCATION_RANGE],
    deadlineStart: filterQueryParams[PROJECT_LIST_DEADLINE_DATE_START],
    deadlineEnd: filterQueryParams[PROJECT_LIST_DEADLINE_DATE_END],
    dateOfConstruction: filterQueryParams[PROJECT_RESEARCH_YEAR_RANGE],
    valuation: filterQueryParams[PROJECT_RESEARCH_VALUATION],
    geoBounds: geoBounds,
  }
}
