import {useEffect, useState} from "react";
import {useQueryParams} from "use-query-params";
import {projectTypeKeyValuePairs} from "../../common/project-type-map";
import {MultiFilterSelect} from "../../components/filters/multi-filter-select";
import {IKeyValuePair} from "../../shared/utils/entity-utils";
import {
  BUILDING_TYPES_1,
  BUILDING_TYPES_2,
  LOCATION_RANGE,
  MANUAL_LOCATION_RANGE,
  PROJECT_LIST_DEADLINE_DATE_END,
  PROJECT_LIST_DEADLINE_DATE_START,
  PROJECT_LIST_TYPE_FILTER,
  PROJECT_RESEARCH_VALUATION,
  PROJECT_RESEARCH_YEAR_RANGE
} from "../../components/filters/project-filter";
import {researchQueryParams} from "./research-query-params";
import {UnitSelect} from "../../components/filters/unit-select";
import {compareArrayWithKeyValuePairArrayByKey, extractKey} from "../../common/compare-helpers";
import {researchObjectTypeKeyValuePairs} from "../../common/research-object-type-map";
import ReactDatePicker from "react-datepicker";
import {MultiYearRangePicker} from "../../components/form-components/multi-year-range-picker";
import {FilterSelect} from "../../components/filters/filter-select";
import {
  buildingSubTypeKeyValuePairs,
  buildingSubTypeMap,
  buildingTypeKeyValuePairs
} from "../../common/building-type-map";

const prepareYearRange = (arr: (number | null)[] | null): [number, number] | null => {
  if(arr && arr[0] !== null && arr[1] !== null) {
    return [arr[0], arr[1]];
  } else {
    return null;
  }
}

function applicable2ndLevelTypes(selected1stLevelTypes: IKeyValuePair[]): IKeyValuePair[] {
  const selectedTypeKeys = selected1stLevelTypes.map(type => type.key)
  return selected1stLevelTypes.length
    ? Object.keys(buildingSubTypeMap)
      .filter((subType) => selectedTypeKeys.includes(buildingSubTypeMap[subType].type))
      .map((subType) => ({
        key: subType,
        label: buildingSubTypeMap[subType].displayString,
      }))
    : buildingSubTypeKeyValuePairs;
}

const possibleValuations = [{label: "Kaufpreis", key: "kp"}, {label: "Verkehrswert", key: "vkw"}, {label: "Alle", key: 'all'}];

export const ResearchLightFilterBar = () => {
  const projectTypes = projectTypeKeyValuePairs;
  const [selectedProjectTypes, setSelectedProjectTypes] = useState<IKeyValuePair[]>([]);
  const [selected1stLevelTypes, setSelected1stLevelTypes] = useState<IKeyValuePair[]>([]);
  const [selected2ndLevelTypes, setSelected2ndLevelTypes] = useState<IKeyValuePair[]>([]);
  const [selectedRange, setSelectedRange] = useState<string>("");
  const [searchParams, setSearchParams] = useQueryParams(researchQueryParams);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [yearRange, setYearRange] = useState<(number | null)[] | null>(null);
  const [valuation, setValuation] = useState<string | null | undefined>("all");


  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if((start && end) || (!start && !end) ) {
      setSearchParams({
        ...searchParams,
        [PROJECT_LIST_DEADLINE_DATE_START]: start,
        [PROJECT_LIST_DEADLINE_DATE_END]: end
      });
    }
  };

  useEffect(() => {
    if (!compareArrayWithKeyValuePairArrayByKey(searchParams[PROJECT_LIST_TYPE_FILTER], selectedProjectTypes)) {
      setSelectedProjectTypes(projectTypeKeyValuePairs.filter(p => (searchParams[PROJECT_LIST_TYPE_FILTER]?.includes(p.key))) || []);
    }
    if (!compareArrayWithKeyValuePairArrayByKey(searchParams[BUILDING_TYPES_1], selected1stLevelTypes)) {
      setSelected1stLevelTypes(researchObjectTypeKeyValuePairs.filter(p => (searchParams[BUILDING_TYPES_1]?.includes(p.key))) || []);
    }
    if (!compareArrayWithKeyValuePairArrayByKey(searchParams[BUILDING_TYPES_2], selected2ndLevelTypes)) {
      setSelected2ndLevelTypes(researchObjectTypeKeyValuePairs.filter(p => (searchParams[BUILDING_TYPES_2]?.includes(p.key))) || []);
    }
    if(searchParams[PROJECT_LIST_DEADLINE_DATE_START] && searchParams[PROJECT_LIST_DEADLINE_DATE_START] !== startDate) {
      setStartDate(searchParams[PROJECT_LIST_DEADLINE_DATE_START] || null)
    }
    if(searchParams[PROJECT_LIST_DEADLINE_DATE_END] && searchParams[PROJECT_LIST_DEADLINE_DATE_END] !== endDate) {
      setEndDate(searchParams[PROJECT_LIST_DEADLINE_DATE_END] || null)
    }
    if (searchParams[PROJECT_RESEARCH_YEAR_RANGE] !== yearRange) {
      setYearRange(searchParams[PROJECT_RESEARCH_YEAR_RANGE] || null);
    }
    if (searchParams[LOCATION_RANGE] !== parseFloat(selectedRange)) {
      // remove trailing 0s in fixed decimal format
      setSelectedRange(searchParams[LOCATION_RANGE]?.toFixed(3).replace(/\.?0+$/, '') || "");
    }
  }, [searchParams])

  return (
    <div
      className="mb-4 w-full grid grid-cols-7 items-center gap-2">
      <UnitSelect
        prefix="Umkreis: "
        suffix=" km"
        value={selectedRange}
        onChange={(newValue) => {
          setSelectedRange(newValue);
          const newRange = parseFloat(newValue);
          if (newRange) {
          setSearchParams({
            ...searchParams,
            [LOCATION_RANGE]: newRange,
            [MANUAL_LOCATION_RANGE]: true,
          })
          }
        }}
        disabled={!searchParams[MANUAL_LOCATION_RANGE]}
      />
      <MultiFilterSelect
        placeholder='Auftragsart'
        selected={selectedProjectTypes}
        onChange={(value) => {
          setSelectedProjectTypes(value);
          setSearchParams({
            ...searchParams,
            [PROJECT_LIST_TYPE_FILTER]: value.map(extractKey)
          });
        }}
        items={projectTypes}
      />
      <MultiFilterSelect
        placeholder='Objektart 1. Ebene'
        selected={selected1stLevelTypes}
        onChange={(value) => {
          setSelected1stLevelTypes(value);
          setSelected2ndLevelTypes([]);
          setSearchParams({
            ...searchParams,
            [BUILDING_TYPES_1]: value.map(extractKey),
            [BUILDING_TYPES_2]: [],
          });
        }}
        items={buildingTypeKeyValuePairs}
      />
      <MultiFilterSelect
        placeholder='Objektart 2. Ebene'
        selected={selected2ndLevelTypes}
        onChange={(value) => {
          setSelected2ndLevelTypes(value);
          setSearchParams({
            ...searchParams,
            [BUILDING_TYPES_2]: value.map(extractKey)
          });
        }}
        items={applicable2ndLevelTypes(selected1stLevelTypes)}
      />
      <div>
        <ReactDatePicker
          selected={startDate}
          showPopperArrow={false}
          isClearable
          onChange={onChange}
          startDate={startDate || null}
          endDate={endDate || null}
          dateFormat="dd.MM.yyyy"
          selectsRange
          monthsShown={2}
          placeholderText="Stichtag"
          locale="de"
          className="w-full py-1.5 rounded-xl font-sans focus:ring-green-500 focus:border-green-500 sm:text-base border-gray-300 z-[10001]"
          calendarClassName="font-special border-gray-300 shadow"
        />
      </div>
      <MultiYearRangePicker
        selected={prepareYearRange(yearRange)}
        onChange={(val) => {
            setYearRange(val);
            setSearchParams({
              ...searchParams,
              [PROJECT_RESEARCH_YEAR_RANGE]: val,
            });
          }
        }
        yearsFrom={1950}
        columns={5}
        placeholder="Baujahr"
        nonNullable={false}
      />
      <FilterSelect
        placeholder=''
        selected={possibleValuations.filter((kvp: IKeyValuePair) => (kvp.key === valuation))?.[0]}
        onChange={(value) => {
          setValuation(value?.key);
          setSearchParams({
            ...searchParams,
            [PROJECT_RESEARCH_VALUATION]: value?.key
          });
        }}
        items={possibleValuations}
      />
      <div className="grow"></div>
    </div>
  )
}
