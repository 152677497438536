import React, {JSX, useCallback, useEffect, useState} from "react";
import {DocumentNode, useLazyQuery} from "@apollo/client";
import {IProjectListItem} from "./project-list-item";
import Alert from "../layout/alert";
import {Spinner} from "../../common/spinner/base-spinner";
import {buildFilter} from "./project-utils";
import InfiniteScroll from "react-infinite-scroll-component";
import {IProjectFilter} from "../filters/project-filter";

export interface IProjectList {
  filter: IProjectFilter,
  pageSize?: number;
  showLoadMore?: boolean;
  sortBy?: { sortBy: string | null | undefined; direction: string | null | undefined } | null | undefined;
  jsFilter?: (project: any) => boolean,
  query: DocumentNode,
  ListItem: ({project, currentProjectId}: IProjectListItem) => JSX.Element
}

const formatSort = (
  sortBy: { sortBy: string | null | undefined; direction: string | null | undefined } | null | undefined
) => {
  return sortBy && sortBy.direction && sortBy.sortBy ? `${sortBy?.sortBy}:${sortBy?.direction}` : "displayId:desc";
};

export const ProjectListWrapper = ({
  filter,
  sortBy,
  pageSize = 20,
  showLoadMore = false,
  jsFilter,
  query,
  ListItem,
}: IProjectList) => {
  const [pagesLoaded, setPagesLoaded] = useState(1);
  const [maxPages, setMaxPages] = useState(2000);
  const [fetchedParams, setFetchedParams] = useState<string>();
  const stringifyParams = useCallback(() => JSON.stringify(filter) + "☺" + JSON.stringify(sortBy), [filter, sortBy]);

  const [fetchProjects, { data: projects, loading, error, fetchMore, refetch, client }] = useLazyQuery(query);

  // TODO fix duplicates...
  // useEffect(() => {
  //   if (projects?.projects){
  //     const ids: any[] = [];
  //     data.forEach(({id}: any, i: number) => {
  //       if (ids.includes(id))
  //         console.log("duplicate at", i, ":", id);
  //       ids.push(id);
  //     });
  //   }
  // }, [projects]);

  useEffect(() => {
    const stringifiedParams = stringifyParams();
    if (stringifiedParams !== fetchedParams) {
      setFetchedParams(fetchedParams);
      fetchProjects({
        notifyOnNetworkStatusChange: true,
        variables: {
          sort: formatSort(sortBy),
          page: 1,
          pageSize: pageSize,
          filters: buildFilter(
            filter
          ),
        },
      })
        // TODO: This is a workaround to remove double entries on filter changes.
        //  For some reason, duplicate items appear if multiple years are selected and multiple pages have been loaded.
        //  (to be fixed later)
        .then(client.resetStore).catch(e => {
        // Invariant Violation occurs if another fetch is currently ongoing, in this case the reset does not matter
        if (e.name !== "Invariant Violation")
          console.error(e);
      });
    }
  }, [fetchProjects, fetchedParams, filter, pageSize, sortBy, stringifyParams]);

  useEffect(() => {
    if (projects?.projects?.meta?.pagination?.pageCount) {
      setMaxPages(projects?.projects?.meta?.pagination?.pageCount);
    }
  }, [projects?.projects?.meta?.pagination?.pageCount])

  useEffect(() => {
    async function resetPagination() {
      await refetch({
        sort: formatSort(sortBy),
        page: 1,
        pageSize: pageSize,
        filters: buildFilter(
          filter
        ),
      });
      setPagesLoaded(2);
    }

    const stringifiedParams = stringifyParams();
    if (stringifiedParams !== fetchedParams) {
      setFetchedParams(stringifiedParams);
      resetPagination();
    }
  }, [fetchedParams, filter, pageSize, refetch, sortBy, stringifyParams]);

  if (error && !projects) {
    return (
      <Alert
        title="Fehler bei der Suche"
        message="Die Auftragsliste kann nicht angezeigt werden."
      />
    );
  }

  let safeJsFilter = jsFilter || (() => true);

  return (
    <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-1 pb-4">
      {loading && pagesLoaded === 1 ? (
        <div className="flex w-full justify-center items-center">
          <Spinner className="stroke-gray-500 w-8 h-8" />
        </div>
      ) :
      projects && projects?.projects?.data?.length > 0 ? (
        <>
          {error && <Alert
              title="Fehler bei der Suche"
              message="Möglicherweise fehlen Aufträge oder werden falsch dargestellt."
              severity="info"
          />}
          <InfiniteScroll
          dataLength={showLoadMore ? projects?.projects?.data?.length || 0 : pageSize}
          hasMore={pagesLoaded <= maxPages && showLoadMore}
          next={async () => {
            await fetchMore({
              variables: {
                page: pagesLoaded,
              }
            });
            setPagesLoaded(pagesLoaded + 1);
          }}
          loader={
            <div className="flex w-full justify-center items-center">
              <Spinner className="stroke-gray-500 w-8 h-8" />
            </div>
          }
          endMessage={
            showLoadMore ? (
              <p className="text-center mt-4 text-gray-500">
                Alle Aufträge geladen
              </p>
            ) : null
          }
        >
          {" "}
          {projects?.projects?.data?.filter(safeJsFilter).map((project: any) => (
            <ListItem key={project.id} project={project} />
          ))}
        </InfiniteScroll>
        </>
      ) : <div className="flex w-full justify-center items-center" >Keine Aufträge gefunden</div> }
    </div>
  );
};
