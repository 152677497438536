import React, {useEffect, useState} from "react";
import {IProjectFilter} from "../../../components/filters/project-filter";
import {buildRestFilter} from "../../../model/meta/request-utils";
import {useLazyFetchAllQuery as useProjectMapLazyFetchAll} from "../../../model/project-map/project-map-api";
import Alert from "../../../components/layout/alert";
import {ProjectsPolygonPickerMap} from "./projects-map";

export interface IProjectsMap {
  filter: IProjectFilter;
  jsFilter?: (project: any) => boolean;
}

export const ProjectMapPolygonContainer = ({ filter, jsFilter }: IProjectsMap) => {
  const [fetchedFilter, setFetchedFilter] = useState<string>();
  const [fetchProjectItems, {
    data: projectMapItems,
    error,
    isFetching
  }] = useProjectMapLazyFetchAll();

  useEffect(() => {
    const stringifiedFilter = JSON.stringify(filter)
    if (stringifiedFilter !== fetchedFilter) {
      setFetchedFilter(stringifiedFilter);
      fetchProjectItems({
        queryParams: {
          filters: buildRestFilter( { ...filter } ),
        },
      })
    }
  }, [filter])

  let safeJsFilter = jsFilter || (() => true);

  if (error) {
    console.error("Error while fetching map data:", error);
    return (
      <Alert
        title={"Karte konnte nicht geladen werden"}
        //@ts-expect-error
        message={error.status === 403
          ? "Sie haben keine Berechtigung, diese Inhalte zu sehen. Falls hier ein Fehler vorliegt, versuchen Sie, sich ab- und wieder anzumelden."
          : "Unbekannter Fehler"}
      />
    );
  }

  return (
    <ProjectsPolygonPickerMap
      projectMapItems={projectMapItems?.data?.filter(safeJsFilter)}
      reloading={isFetching}
    />
  );
};
