import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { projectTypeMap } from "../../common/project-type-map";
import {IByMonthData} from "./apiHooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const align: "end" | "start" | "center" | undefined = "end";
const positionLegend: "center" | "top" | "left" | "right" | "bottom" | "chartArea" | undefined = "top";

export const options = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        color: '#F1F1F5',
      },
    },
    y: {
      stacked: true,
      grid: {
        display: true,
        drawBorder: false,
      },
      ticks: {
        display: true,
      },
    },
  },
};

const labels = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

export const generateBarConfig = (types: Set<String>, data: IByMonthData) => {
  const { twoYearsAgo = [], lastYear = [], currentYear =[] } = data;
  return {
    labels,
    datasets: [...Array.from(types).map((type: any) => {
      return {
        label: projectTypeMap[type]?.displayString,
        data: Object.values(twoYearsAgo).map((item) => item[type] || 0 ),
        backgroundColor: projectTypeMap[type]?.offColor,
        borderRadius: 4,
        barPercentage: 0.6,
        stack: "1",
        legend: false,
      };
    }),
    ...Array.from(types).map((type: any) => {
      return {
        label: projectTypeMap[type]?.displayString,
        data: Object.values(lastYear).map((item) => item[type]),
        backgroundColor: projectTypeMap[type]?.offColor,
        borderRadius: 4,
        barPercentage: 0.6,
        stack: "2",
        legend: false,
      };
    }),
    ...Array.from(types).map((type: any) => {
      return {
        label: projectTypeMap[type]?.displayString,
        data: Object.values(currentYear).map((item) => item[type]),
        backgroundColor: projectTypeMap[type]?.color,
        borderRadius: 4,
        barPercentage: 0.6,
        stack: "3"
      };
    }),
  ],
  };
};

export function OrderOverview({ data }: {data: IByMonthData}) {
  const { twoYearsAgo = [], lastYear = [], currentYear =[] } = data;
  const types = [...twoYearsAgo, ...lastYear, ...currentYear].reduce((acc, item) => {
    Object.keys(item).forEach((key) => {
      acc.add(key);
    });
    return acc;
  }, new Set<string>());
  const feData = generateBarConfig(types, data);
  return <>
    <div className="text-xs text-gray-400 flex flex-row flex-wrap justify-end">{Array.from(types).map(t => (<div key={projectTypeMap[t]?.displayString}>
      <div className="flex flex-row h-5 md:h-6 font-sans text-base items-center mr-4">
        <div className="h-3 w-3 md:w-4 md:h-4 rounded-full text-white mr-2 shrink-0 px-1.5 pt-1" style={{ backgroundColor: projectTypeMap[t]?.color }}>
        </div>{" "}
        <div className="text-xs">{projectTypeMap[t]?.displayString}</div>
      </div>
    </div>))}</div>
    <div className="h-96">
      <Bar options={options} data={feData} />
    </div>
  </>;
}
