import React from "react";
import {MagicUpdate} from "../../../components/magic-update/magic-update";
import {useFetchOneProjectResearchQuery} from "../../../model/project-research/project-research-api";
import {getLoginUrl} from "../../../shared/utils/url-utils";
import {getItemTransparent} from "../../../shared/utils/storage-utils";
import axios from "axios";
import {toast} from "react-toastify";
import {AuthDownloadItem} from "../../../components/layout/auth-download-item";
import {UploadButton} from "../../../components/basics/upload-button";
import {classNames} from "../../../shared/utils/class-names";
import {h3Classes, h4Classes} from "../../../components/basics/typography-classes";
import {Accordion} from "../../../components/layout/basics/accordion";
import {CalculatedItem} from "../../../components/calculated-item";
import {defaultButtonClasses} from "../../../components/basics/buttons-classes";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {formatBigNumber} from "../../../shared/utils/formatters";
import {researchFieldInfo} from "./research-field-info";
import valXmlTranslations from "./ValXML-translations.json";
import {
  AREA_ATTRIBUTE_TYPES,
  calcBodenwertPerQm,
  calcBruttoAnfangsrendite,
  calcCap,
  calcFaktorAktuell,
  calcFaktorMarkt,
  calcGesamtmietfläche,
  calcMarktertrag,
  calcNettoAnfangsrendite,
  calcRohertrag,
  calcWert,
  calcWertPerQm,
  NUMBER_ATTRIBUTE_TYPES,
} from "./research-calc";
import ResearchAccordionLetArea from "./research-accordion-let-area";

const attributesWithExplicitHandling = [
  "valxml",
  "useForResearch",
  "dateOfAppraisal",
  "qualityDateOfAppraisal",
  "purchasePrice",
  "iSOCurrencyCodeType",
  "marketValue",
  "priceOfSale",
  "landSize",
  "landValue",
  "faktorNichtUmlagefaehigeBetriebskosten",
  "faktorAnschaffungsnebenkosten",
  "createdAt",
  "updatedAt"
].concat(
  AREA_ATTRIBUTE_TYPES
    .flatMap(value => ["rentalSituation" + value + "LetArea", "rentalSituation" + value + "VacArea"]))
  .concat(
    NUMBER_ATTRIBUTE_TYPES
      .flatMap(value => ["rentalSituation" + value + "LetNumbers", "rentalSituation" + value + "VacantNumbers"])
  );

export const Research = ({ project, reload }: any) => {
  const projectResearchId = project?.project?.data?.attributes?.project_research?.data?.id;
  const {
    data: projectResearchData,
    error,
    isLoading,
    refetch
  } = useFetchOneProjectResearchQuery({ id: projectResearchId, queryParams: { populate: ["valxml"] } })
  const researchAttributes: any = projectResearchData?.data?.attributes;


  const triggerXmlImport = async () => {
    try {
      const result = await axios.post(
        `${getLoginUrl()}/api/project-researches/${projectResearchId}/importxml`,
        {},
        {
          headers: {
            Authorization: "Bearer " + getItemTransparent("JWT"),
          },
        }
      );
      if (result?.data?.id) {
        toast.info("XML erfolgreich importiert.")
        return result?.data;
      }
    } catch (e) {
      toast.error("Import des XML ist fehlgeschlagen. Bitte Datei löschen und erneut versuchen.")
    }
  };


  return (
    <>
      <Accordion
        header={<h3 className={classNames(...h3Classes, "text-green-500")}>Zusammenfassung</h3>}
        bgColorClasses="bg-[#004F150A]"
      >
        <div className="grid gap-y-4 grid-cols-2 lg:grid-cols-5">
          <CalculatedItem label="Verkehrswert" value1={calcWert(researchAttributes, "VKW")}
                          value2={calcWertPerQm(researchAttributes, "VKW")} unit1={researchAttributes?.currency}
                          unit2="" className="font-bold"/>
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(researchAttributes, "VKW")}
                          unit1="%"/>
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(researchAttributes, "VKW")}
                          unit1="%"/>
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(researchAttributes, "VKW")} unit1=""/>
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(researchAttributes, "VKW")} unit1=""/>
        </div>
        <div className="grid gap-y-4 grid-cols-2 lg:grid-cols-5 mt-3">
          <CalculatedItem label="Kaufpreis" value1={calcWert(researchAttributes, "KP")}
                          value2={calcWertPerQm(researchAttributes, "KP")} unit1={researchAttributes?.currency} unit2=""
                          className="font-bold text-black"/>
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(researchAttributes, "KP")}
                          unit1="%"/>
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(researchAttributes, "KP")}
                          unit1="%"/>
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(researchAttributes, "KP")} unit1=""/>
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(researchAttributes, "KP")} unit1=""/>
        </div>
      </Accordion>
      <div className="flex flex-row justify-end items-center my-3 gap-2">
        <div className="flex items-center text-sm font-bold">In Research einbeziehen
        </div>
        <MagicUpdate
          model="projectResearch"
          id={projectResearchId}
          reload={refetch}
          type="BooleanSwitch"
          gqlType="Boolean"
          field="useForResearch"
          extra={{
            buttonTextTrue: 'ausschließen',
            buttonTextFalse: 'einbeziehen'
          }}
          value={researchAttributes?.useForResearch}
        />
        <div className="">&nbsp;</div>
        <UploadButton
          key={projectResearchId}
          objectId={projectResearchId}
          objectTypeRef="api::project-research.project-research"
          objectField="valxml"
          reload={async () => { await triggerXmlImport(); reload(); refetch(); }}
          fileTypeAccept="*"
          fileTypeText="ValXML-Files"
          buttonText="Upload ValXML"
          disabled={researchAttributes?.valxml?.data?.id}
        />
      </div>
      <div>
        <h4 className={classNames(...h4Classes, "mb-2")}>Berechnungsfaktoren (nicht aus ValXML)</h4>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 mb-3 text-sm">

          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"faktorNichtUmlagefaehigeBetriebskosten"}
            reload={refetch}
            type="Number"
            gqlType="Float"
            formatter={(val:any) => (val ? `${val} %` : "-")}
            field="faktorNichtUmlagefaehigeBetriebskosten"
            label={"Nicht umlagefähige Bewirtschaftungskosten"}
            value={researchAttributes?.faktorNichtUmlagefaehigeBetriebskosten}
          />
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"faktorAnschaffungsnebenkosten"}
            reload={refetch}
            type="Number"
            gqlType="Float"
            formatter={(val:any) => (val ? `${val} %` : "-")}
            field="faktorAnschaffungsnebenkosten"
            // &#173; (or &shy;) breaks the word and inserts a hyphen if necessary
            label={"Anschaffungs" + String.fromCharCode(173) + "nebenkosten"}
            value={researchAttributes?.faktorAnschaffungsnebenkosten}
          />
        </div>
        <h4 className={classNames(...h4Classes, "mb-2")}>Allgemeine Daten (aus ValXML)</h4>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"priceOfSale"}
            reload={refetch}
            type="Number"
            gqlType="Float"
            formatter={formatBigNumber}
            field="priceOfSale"
            label={"Kaufpreis (in Gutachtenwährung)"}
            value={researchAttributes?.priceOfSale}
          />
          <CalculatedItem label="Kaufpreis / Gesamtmietfläche" value1={calcWertPerQm(researchAttributes, "KP")}
                          unit1={``}/>
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"currency"}
            reload={refetch}
            type="String"
            field="currency"
            label={"Gutachtenwährung"}
            value={researchAttributes?.currency}
          />
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"arealUnit"}
            reload={refetch}
            type="String"
            field="arealUnit"
            label={"Flächeneinheit"}
            value={researchAttributes?.arealUnit}
          />
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"marketValue"}
            reload={refetch}
            type="Number"
            gqlType="Float"
            formatter={formatBigNumber}
            field="marketValue"
            label={"Verkehrswert (Zum Stichtag, In Gutachtenwährung)"}
            value={researchAttributes?.marketValue}
          />
        </div>
      </div>
      <div>
        <h4 className={classNames(...h4Classes, "mb-2")}>Grundstück (aus ValXML)</h4>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"landSize"}
            reload={refetch}
            type="Number"
            gqlType="Float"
            field="landSize"
            label={"Grundstücksfläche"}
            value={researchAttributes?.landSize}
            formatter={formatBigNumber}
          />
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"landValue"}
            reload={refetch}
            type="Number"
            gqlType="Float"
            formatter={formatBigNumber}
            field="landValue"
            label={"Bodenwert (in Gutachtenwährung)"}
            value={researchAttributes?.landValue}
          />
          <CalculatedItem label="Bodenwert / Grundstücksfläche" value1={calcBodenwertPerQm(projectResearchData)} unit1="" />
        </div>
      </div>
      <div>
        <h4 className={classNames(...h4Classes, "mb-2")}>Gebäude (aus ValXML)</h4>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
          <MagicUpdate
            model="building"
            id={project?.project?.data?.attributes?.building?.data?.id}
            reload={reload}
            type="ENUM_BUILDING_TYPE"
            field="type"
            label="Objektart 1. Ebene"
            value={project?.project?.data?.attributes?.building?.data?.attributes?.type}
          />
          <MagicUpdate
            model="building"
            id={project?.project?.data?.attributes?.building?.data?.id}
            reload={reload}
            type="ENUM_BUILDING_SUBTYPE"
            field="subtype"
            label="Objektart 2. Ebene"
            value={project?.project?.data?.attributes?.building?.data?.attributes?.subtype}
            extra={{ buildingType: project?.project?.data?.attributes?.building?.data?.attributes?.type  }}
          />
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"originalYearOfConstructionAsYearNumber"}
            reload={refetch}
            type="Number"
            gqlType="Int"
            field="originalYearOfConstructionAsYearNumber"
            label={"Ursprungsbaujahr"}
            value={researchAttributes?.originalYearOfConstructionAsYearNumber}
          />
        </div>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"totalRentableArea"}
            reload={refetch}
            type="String"
            field="totalRentableArea"
            formatter={formatBigNumber}
            label={"Gesamtmietfläche (manuell)"}
            value={researchAttributes?.totalRentableArea}
          />
          <CalculatedItem label="Gesamtmietfläche (berechnet)" value1={calcGesamtmietfläche(researchAttributes)}/>
        </div>
      </div>
      <div className="my-4">
        <ResearchAccordionLetArea projectResearchId={projectResearchId} refetch={refetch}
                                  researchAttributes={researchAttributes}/>
      </div>
      <div className="mt-4">
        <h4 className={classNames(...h4Classes, "mb-2")}>Vermietungssituation (aus ValXML)</h4>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
          <CalculatedItem label="Rohertrag aktuell (inkl. Leerstandsertrag, absolute Nettokaltmiete pro Jahr)"
                          value1={calcRohertrag(projectResearchData)} unit1={researchAttributes?.currency}/>
          <CalculatedItem label="Rohertrag marktüblich (absolute Nettokaltmiete pro Jahr)"
                          value1={calcMarktertrag(projectResearchData)} unit1={researchAttributes?.currency}/>
        </div>
      </div>
      <div className="mt-4">
        <h4 className={classNames(...h4Classes, "mb-2")}>Wertermittlungsergebnisse (aus ValXML)</h4>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-5 mt-3 text-sm">
          <CalculatedItem label="Verkehrswert" value1={calcWert(researchAttributes, "VKW")}
                          value2={calcWertPerQm(researchAttributes, "VKW")} unit1={researchAttributes?.currency}
                          unit2=""
                          className="font-bold"/>
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(researchAttributes, "VKW")}
                          unit1="%"/>
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(researchAttributes, "VKW")}
                          unit1="%"/>
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(researchAttributes, "VKW")} unit1=""/>
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(researchAttributes, "VKW")} unit1=""/>
        </div>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-5 mt-3 text-sm">
          <CalculatedItem label="Kaufpreis" value1={calcWert(researchAttributes, "KP")}
                          value2={calcWertPerQm(researchAttributes, "KP")} unit1={researchAttributes?.currency} unit2=""
                          className="font-bold text-black"/>
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(researchAttributes, "KP")}
                          unit1="%"/>
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(researchAttributes, "KP")}
                          unit1="%"/>
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(researchAttributes, "KP")} unit1=""/>
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(researchAttributes, "KP")} unit1=""/>
        </div>
        <div className="grid gap-6 mt-3 text-sm">
          <CalculatedItem label="Cap-Rate" value1={calcCap(projectResearchData)} unit1="%"/>
        </div>
      </div>
      <div className="my-4">
        <Accordion
          header={<h4 className={classNames(...h4Classes)}>ValXML-Daten</h4>}
          bgColorClasses="bg-gray-50"
        >
          <div className="bg-white p-3 rounded-lg grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
            {
              researchAttributes !== undefined
                ? Object.keys(researchAttributes)
                  .filter((key: string) => (!attributesWithExplicitHandling.includes(key)))
                  .map((key: string) => {
                    const strVal = researchAttributes[key];
                    if(researchFieldInfo[key]?.type === "enumeration") {
                      return <MagicUpdate
                        model="projectResearch"
                        id={projectResearchId}
                        key={key}
                        reload={refetch}
                        type={"GENERIC_ENUM"}
                        gqlType={researchFieldInfo[key]?.gqlType}
                        field={key}
                        //@ts-expect-error
                        label={valXmlTranslations[key] || key}
                        extra={{
                          displayName: researchFieldInfo[key]?.labelMap[strVal],
                          labelMap: researchFieldInfo[key]?.labelMap
                        }}
                        value={strVal}
                      />
                    } else {
                      return <MagicUpdate
                        model="projectResearch"
                        id={projectResearchId}
                        key={key}
                        reload={refetch}
                        type={researchFieldInfo[key]?.type}
                        gqlType={researchFieldInfo[key]?.gqlType || researchFieldInfo[key]?.type}
                        field={key}
                        //@ts-expect-error
                        label={valXmlTranslations[key] || key}
                        value={strVal}
                      />
                    }
                  })
                : null
            }
          </div>
        </Accordion>
      </div>
      {researchAttributes?.valxml?.data
        ? <div className="mt-5">
          <h5 className={classNames(...h4Classes)}>Aktiver Upload</h5>
          <div key={'valxml'} className="truncate group flex flex-row items-center text-blue-500">
            <AuthDownloadItem item={researchAttributes?.valxml?.data} afterDelete={refetch}/>
            <div className={classNames(...defaultButtonClasses, "text-sm flex flex-row gap-1 cursor-pointer")} onClick={async () => { await triggerXmlImport(); reload(); refetch(); }}><ArrowPathIcon className="w-5 h-5" /> Wiederherstellen</div>
          </div>
        </div>
        : null}
    </>
  );
};
