import React, { useEffect, useState } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import { Button } from "../../components/basics/button";
import { Card } from "../../components/basics/card";
import { Input } from "../../components/form-components/input";
import { useForm } from "react-hook-form";
import { getItemTransparent } from "../utils/storage-utils";

import { login } from "./authentication";
import Alert from "../../components/layout/alert";
import { ValidationMessage } from "../../components/layout/validation";

// TODO: Move all of this into a store slice

const defaultErrorState = { isError: false, title: "", message: "" };

export const Login = (props: any) => {
  const [error, setError] = useState({ ...defaultErrorState });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = async (data: any) => {
    const { identifier, password, rememberMe } = data;
    try {
      await login(identifier, password, rememberMe);
      setError({ ...defaultErrorState });
      navigate(searchParams.get("redirectTo") ?? "/");
    } catch (e) {
      setError({
        isError: true,
        title: "Fehler beim Login",
        message: "Bitte überprüfen Sie die eingegebenen Daten.",
      });
    }
  };

  useEffect(() => {
    const isAuthenticated = getItemTransparent("JWT");

    if (isAuthenticated) {
      navigate(searchParams.get("redirectTo") ?? "/");
    }
  });

  return (
    <div className="h-screen bg-green-500 flex flex-col items-center justify-center">
      <Card className="xl:w-1/4 md:w-2/5 bg-white">
        <div className="container mx-auto flex flex-col items-center w-full">
          <img
            src="../logo_green.png"
            className="w-[194px] mb-8 mt-4"
            alt="Logo"
          />
          <h1 className="font-special font-semibold text-xl mt-4 mb-4 text-green-900">
            Login
          </h1>
          <form
            className="w-full"
            method="post"
            onSubmit={handleSubmit(handleLogin)}
          >
            <div className="mt-2 mb-2">
              <Input
                className={`w-full text-base ${
                  errors.identifier ? "border-red-800 placeholder-red-800" : ""
                }`}
                placeholder="Nutzername/E-Mail"
                type="text"
                register={register}
                validateRequired={{
                  required: "Bitte Username oder E-Mail eingeben",
                }}
                name="identifier"
              />
              {errors.identifier?.type === "required" && (
                <ValidationMessage message="Bitte Nutzername oder E-Mail eingeben" />
              )}
            </div>
            <div className="mt-2 mb-2">
              <Input
                className={`w-full text-base ${
                  errors.identifier ? "border-red-800 placeholder-red-800" : ""
                }`}
                placeholder="Password"
                type="password"
                register={register}
                validateRequired={{
                  required: "Bitte Passwort eingeben",
                }}
                name="password"
              />
              {errors.password?.type === "required" && (
                <ValidationMessage message="Bitte Passwort eingeben" />
              )}
            </div>
            <div className="flex flex-row justify-between mb-4 mt-4">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    type="checkbox"
                    className="rounded checked:bg-orange-500 checked:outline-orange-500 focus:outline-orange-500 active:bg-orange-500 active:outline-orange-500 checked:focus:bg-orange-500 h-4 w-4"
                    {...register("rememberMe")}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="rememberMe"
                    className="text-gray-500 text-base"
                  >
                    Angemeldet bleiben
                  </label>
                </div>
              </div>
              <div className="">
                <a
                  href="/forgot-password"
                  className="text-base text-green-500 font-medium"
                  title="Passwort vergessen"
                >
                  Passwort vergessen
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center mb-8 mt-8">
              <Button primary type="submit" className="font-semibold">
                Login
              </Button>
            </div>
            {error.isError ? (
              <Alert title={error.title} message={error.message} />
            ) : null}
          </form>
        </div>
      </Card>
    </div>
  );
};

export default Login;
